import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ButtonComp from './../../Components/UI/Button/Button';
import Typography from './../../Components/UI/Typography/Typography';
import TextFieldComp from './../../Components/UI/Input/TextField';
import ErrorBoundary from './../../Errors/ErrorBoundary';
import { BiUpload } from 'react-icons/bi';
const Main = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Button = styled(ButtonComp)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const Upload = styled('div')(({ theme }) => ({
  border: '1px solid black',
  borderRadius: '6px',
  cursor: 'pointer',
  width: '20rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
}));
const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: '6px 6px 0px 0px',
}));

const CourseModel = ({ updateHandler, selected }) => {
  const [value, setValue] = React.useState('');
  const [preview, setPreview] = React.useState();
  const [imageBase64, setImageBase64] = React.useState();
  const categoryTitle = selected.row.title;

  const imageHandler = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setImageBase64(fileReader.result);
    };
    setPreview(objectUrl);
  };

  useEffect(() => {
    setValue(categoryTitle);
  }, []);
  return (
    <Main>
      <ErrorBoundary>
        <Typography>Edit Course: </Typography>
        <TextFieldComp
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label=""
          fullWidth
        />

        <br />
        <br />

        <div>
          <Typography>Edit image: </Typography>

          <input
            onChange={imageHandler}
            id="img"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
          />

          {/* WE HAVE TO DO THE CHECKBOX FUNCTIONALITY IN IMAGES ALSO WHEN WE GET API */}
          <Upload>
            <Image src={preview} />

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BiUpload />
              <label
                htmlFor="img"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                Upload image
              </label>
            </div>
          </Upload>
        </div>
        <ButtonWrapper>
          <Button onClick={(e) => updateHandler(value, imageBase64)}>
            Update
          </Button>
        </ButtonWrapper>
      </ErrorBoundary>
    </Main>
  );
};

export default CourseModel;
