import api from '../../../services';
import { useSnackbar } from 'notistack';
import { Upload } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Table from '../../../Components/UI/Table/Table';
import ErrorBoundary from '../../../Errors/ErrorBoundary';
import Loader from '../../../Components/UI/Loader/Loader';
import React, { useEffect, useState } from 'react';
import ButtonComp from '../../../Components/UI/Button/Button';
import { FormControl, IconButton, TextField } from '@mui/material';
import PromptSnackbar from '../../../Components/UI/Snackbar/PromptSnackbar';

const ConfigureSimulations = ({ selected }) => {
  const [data, setData] = useState({
    title: '',
    src: '',
    imgURL: '',
  });

  const [errors, setErrors] = useState({
    title: false,
    src: false,
    imgURL: false,
  });

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [simulationList, setSimulationList] = useState([]);
  const [simulation, setSimulation] = useState(null);
  const [simulationId, setSimulationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteSubject, setDeleteSubject] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const { enqueueSnackbar } = useSnackbar();

  const imageHandler = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setData((prev) => {
        return {
          ...prev,
          imgURL: fileReader.result,
        };
      });
    };

    setErrors({ ...errors, imgURL: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear error when user starts typing
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const handleSubmit = () => {
    let hasError = false;
    const newErrors = {};
    // Check for empty fields
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        newErrors[key] = true;
        hasError = true;
      }
    });

    if (hasError) {
      setErrors(newErrors);
    } else {
      // Clear data and errors
      setData({ title: '', src: '', imgURL: '' });
      setErrors({ title: false, src: false, imgURL: false });

      const payload = {
        ...data,
        chapterId: selected?.chapId,
        snackId: selected?.id,
      };

      addEditSimulation(payload);
      setSimulationId(null);
      setLoading(true);
    }
  };

  const handleClick = (newState) => {
    setDeleteSubject({ open: true, ...newState });
  };

  const fields = [
    { field: 'id' },
    { field: 'title', headerName: 'Simulation Title', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => {
              setSimulationId(params?.row?.id);

              setData({
                title: params?.row?.title,
                src: params?.row?.src,
                imgURL: params?.row?.imgURL,
              });

              setErrors(false);

              setShowForm(true);
            }}
          >
            <EditOffIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            style={{ color: 'red' }}
            onClick={() => {
              handleClick({
                vertical: 'top',
                horizontal: 'center',
              });

              setSimulation(params?.row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // Create a simulation

  const addEditSimulation = async (payload) => {
    const result = await api.post.createSimulation(payload, simulationId);

    if (result?.error) {
      setLoading(false);
      setShowForm(false);

      enqueueSnackbar(
        `OOPS! error occoured while ${
          simulationId ? 'updating' : 'creating'
        } a simulation, Please try again`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      setLoading(false);
      setShowForm(false);
      enqueueSnackbar(
        `Simulation has been ${
          simulationId ? 'updated' : 'created'
        } successfully!`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      );

      getSimulations();
    }
  };

  // Get simulations
  const getSimulations = async () => {
    const temp = await api.get.getSimulations({ snackId: selected?.id });
    setSimulationList(temp);
    setDataIsLoading(false);
  };

  // Delete a Simulation
  const deleteSimulationHandler = async () => {
    const result = await api.delete.deleteSimulation(simulation?.id);

    if (result?.error) {
      enqueueSnackbar(
        'OOPS! error occoured while deleting a simulation, Please try again',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      getSimulations();
      setDeleteSubject({ ...deleteSubject, open: false });
      enqueueSnackbar('Simulation has been deleted successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    setDataIsLoading(true);
    getSimulations();
  }, [selected]);

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <ButtonComp
          style={{ width: '100%' }}
          onClick={() => {
            setShowForm(!showForm);
            setSimulationId(null);

            setData({ title: '', src: '', imgURL: '' });
          }}
        >
          {showForm ? 'Show Simulations' : ' Add Simulation'}
        </ButtonComp>
      </div>

      {showForm ? (
        <>
          <FormControl
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <TextField
              label="Add Title"
              onChange={handleChange}
              name="title"
              required
              error={errors.title}
              helperText={errors.title ? 'Title is required' : ''}
              value={data.title}
            />

            <TextField
              label="Add Src"
              onChange={handleChange}
              name="src"
              required
              error={errors.src}
              helperText={errors.src ? 'Src is required' : ''}
              value={data.src}
            />

            <div>
              <input
                onChange={imageHandler}
                id="img"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
              />

              {data?.imgURL && (
                <img
                  src={data?.imgURL}
                  alt="img"
                  style={{ height: 100, width: 100, borderRadius: 20 }}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex', gap: 10 }}>
                  <Upload></Upload>

                  <label htmlFor="img" style={{ cursor: 'pointer' }}>
                    {' '}
                    {data?.imgURL ? 'Update image' : 'Upload image'}{' '}
                  </label>
                </div>

                {errors?.imgURL && (
                  <p style={{ color: '#D32f2f', fontSize: 12, marginLeft: 14 }}>
                    Image is required
                  </p>
                )}
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <ButtonComp onClick={handleSubmit}>
                {simulationId ? 'Update' : 'Add'} Simulation
              </ButtonComp>
            )}
          </FormControl>
        </>
      ) : (
        <>
          <ErrorBoundary>
            {dataIsLoading ? (
              <Loader />
            ) : (
              <Table tableData={simulationList} cols={fields} />
            )}
          </ErrorBoundary>

          <PromptSnackbar
            setState={setDeleteSubject}
            state={deleteSubject}
            clickHandler={deleteSimulationHandler}
          >
            Do you want to Delete this Simulation
          </PromptSnackbar>
        </>
      )}
    </>
  );
};

export default ConfigureSimulations;
