import { styled } from '@mui/material/styles';

export const ModelWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Wrapper = styled('div')(() => ({
  maxHeight: '300px',
  overflow: 'auto',
}));

export const TableWrapper = styled('table')(() => ({
  width: '100%',
  borderCollapse: 'collapse',
}));

export const CustomTh = styled('th')(() => ({
  border: '1px solid black',
  padding: '8px',
}));

export const CustomTd = styled('td')(() => ({
  border: '1px solid black',
  padding: '8px',
}));
